<template>
  <div class="container">
    <TitleBarBaseVue />
    <el-form ref="form" :model="sysform" :rules="rules" label-width="auto">
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item prop="brandName" label="品牌名称">
            <el-tooltip class="item" effect="dark" content="输入品牌名称" placement="top">
              <el-input v-model="sysform.brandName" placeholder="输入品牌名称"> </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="brandPhone" label="品牌电话">
            <el-tooltip class="item" effect="dark" content="输入品牌电话" placement="top">
              <el-input v-model="sysform.brandPhone" placeholder="输入品牌电话"> </el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="companyName" label="公司全称">
            <el-tooltip class="item" effect="dark" content="输入公司全称" placement="top">
              <el-input v-model="sysform.companyName" placeholder="输入公司全称"> </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="companyUrl" label="公司官网">
            <el-tooltip class="item" effect="dark" content="输入公司官网" placement="top">
              <el-input v-model="sysform.companyUrl" placeholder="输入公司官网"> </el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item prop="userAgreement" label="用户协议">
            <Tinymce v-model="sysform.userAgreement" :height="400" />
          </el-form-item>
          <el-form-item prop="privacyPolicy" label="隐私政策">
            <Tinymce v-model="sysform.privacyPolicy" :height="400" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { brandInfoGet, brandInfoSet } from '@/api/systemController'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'

import Tinymce from '@/components/Tinymce/index.vue'
export default {
  components: {
    Tinymce,
    TitleBarBaseVue
  },
  data() {
    let timeToSend = (rule, value, callback) => {
      let reg = /^(([1-9])|(1\d)|(2[0-4]))$/
      // console.log(reg.test(value))
      if (!reg.test(value)) {
        return callback(new Error('请输入1-24数字'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        brandName: [{ required: true, message: '输入品牌名称', trigger: 'blur' }],
        brandPhone: [{ required: true, message: '输入品牌电话', trigger: 'blur' }],
        companyName: [{ required: true, message: '输入公司全称', trigger: 'blur' }],
        companyUrl: [{ required: true, message: '输入公司官网', trigger: 'blur' }],
        privacyPolicy: [{ required: true, message: '用户协议', trigger: 'blur' }],
        userAgreement: [{ required: true, message: '隐私政策', trigger: 'blur' }]
      },
      sysform: {
        brandName: '',
        brandPhone: '',
        companyName: '',
        companyUrl: '',
        userAgreement: '',
        privacyPolicy: ''
      }
    }
  },
  methods: {
    handleSave() {
      this.$refs['form'].validate(async valid => {
        if (!valid) return
        // console.log(this.sysform)
        let [err, res] = await brandInfoSet(this.sysform)
        if (err) {
          console.log(err)
          return this.$message.error(err.message || '保存失败')
        }
        console.log(res)
        this.$message.success(res.message || '保存成功')
      })
    },
    async getSystemInfo() {
      let [err, res] = await brandInfoGet()
      if (err) {
        console.log(err)
        return this.$message.error(err.message || '获取失败')
      }
      console.log(res)
      if (!res.data) return
      this.sysform = res.data
    }
  },
  mounted() {
    this.getSystemInfo()
  }
}
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  width: 100%;
}
</style>
