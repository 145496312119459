import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取系统信息
 * @returns
 */
export const sysInfoGet = () => {
  return awaitWrap(
    request({
      url: `/sys/info/get`,
      method: 'get',
      isLoading: false
    })
  )
}
/**
 * 获取系统logo
 * @returns
 */
export const getCompanyImg = () => {
  return awaitWrap(
    request({
      url: `/getCompanyImg`,
      method: 'post',
      isLoading: false
    })
  )
}

/**
 * 保存系统信息
 * @returns
 */
export const sysInfoSet = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/sys/info/set`,
      method: 'POST',
      data,
      isLoading: true
    })
  )
}

/**
 * 保存接口配置信息
 * @param {*} "appid": "string",
 * @param {*} "appsercret": "string",
 * @param {*} "encodingaeskey": "string",
 * @param {*} "msgdataformat": "string",
 * @param {*} "token": "string",
 * @param {*} "url": "string"
 * @returns
 */
export const updateSysSetting = () => {
  return awaitWrap(
    request({
      url: `/company/wx/info`,
      method: 'GET',
      isLoading: true
    })
  )
}

/**
 * 获取品牌信息
 * @returns
 */
export const brandInfoGet = () => {
  return awaitWrap(
    request({
      url: `/brand/info/get`,
      method: 'GET',
      isLoading: false
    })
  )
}

/**
 * 设置品牌信息
 * @returns
 */
export const brandInfoSet = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/brand/info/set`,
      method: 'POST',
      data,
      isLoading: true
    })
  )
}

/**
 * 保存常见问题
 * @param {*} "question": "string"
 * @returns
 */
export const saveSysQuestion = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/sys/question/add`,
      method: 'POST',
      data,
      isLoading: true
    })
  )
}


/**
 * 获取常见问题
 * @returns
 */
export const sysQuestion = () => {
  return awaitWrap(
    request({
      url: `/sys/question`,
      method: 'get',
      isLoading: true
    })
  )
}


